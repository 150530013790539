import React, { useState, lazy, Suspense } from 'react';
import { useQuery } from '@apollo/react-hooks';
import cx from 'classnames';
import { Link } from '@reach/router';

import BillStatements from './BillStatements';
import LoadingPartial from '../Loading/LoadingPartial';
import Card from '../Card/Card';
import SMSModal from './SMSModal';

import gqlQuery, { CreateImpersonatedLoginSession } from './gql-query';
import { onError, currencyFormatter } from '../../utils';
import { MYPAIDY_HOST, IMPERSONATE_SESSION_TIMEOUT } from '../../constants';

import styles from '../ConsumerPage/ConsumerPage.module.scss';

const Charges = lazy(() => import('./Charges'));
const MultiPayContracts = lazy(() => import('./MultiPayContracts'));

type AccountPageProps = {
  consumerId: string;
};

const AccountPage = ({ consumerId }: AccountPageProps) => {
  const [isSMSModalOpen, setIsSMSModalOpen] = useState(false);
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { consumerId },
  });
  const { loading: impersonatedLoading, data: impersonatedData } = useQuery(
    CreateImpersonatedLoginSession,
    {
      onError,
      variables: { consumerId },
      pollInterval: IMPERSONATE_SESSION_TIMEOUT,
    }
  );

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.consumer) {
    return <div className="na-box" />;
  }

  const {
    billstatementsWithSettlements,
    consumer,
    plusstatus: { ekycStatus, houkatsuStatus, appleStatus },
  } = data;
  const createImpersonatedLoginSession =
    !impersonatedLoading && impersonatedData.createImpersonatedLoginSession;

  return (
    <>
      <SMSModal
        isOpen={isSMSModalOpen}
        setIsOpen={setIsSMSModalOpen}
        consumerId={consumerId}
        phone={consumer.phone}
      />
      <Card>
        <h3>顧客アカウント</h3>
        <h4 className={styles.id}>
          顧客ID<span>{consumerId}</span>
        </h4>
        <section className={styles['action-section']}>
          <nav className={styles.options}>
            <button
              onClick={() => {
                setIsSMSModalOpen(!isSMSModalOpen);
              }}
              className={cx(styles.option, styles.red)}
            >
              SMSを送る
            </button>
            {createImpersonatedLoginSession &&
              createImpersonatedLoginSession.id && (
                <a
                  href={`${MYPAIDY_HOST}/impersonate?token=${createImpersonatedLoginSession.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.option}
                >
                  Impersonate
                </a>
              )}
          </nav>
          {/* $FlowFixMe */}
          <Link
            className={cx('btn', styles.account)}
            to={`/consumers/${consumerId}/details`}
          >
            顧客情報
          </Link>
        </section>
        <section className={styles['info-section']}>
          <div>
            <div className={styles.label}>{consumer.kanaName || '\u00A0'}</div>
            <div className={cx(styles.value, styles.name)}>
              <span className={styles.kanji}>{consumer.kanjiName}</span>
              <span className={styles['tag-group']}>
                <span
                  className={`tag ${
                    consumer.status ? consumer.status.toLowerCase() : ''
                  }`}
                >
                  {consumer.status}
                </span>
                {ekycStatus === 'approved' && (
                  <span className={`tag plus-ekyc`}>PLUS EKYC</span>
                )}
                {houkatsuStatus === 'approved' && (
                  <span className={`tag plus-houkatsu`}>PLUS包括</span>
                )}
                {houkatsuStatus === 'approved_for_nlp' && (
                  <span className={`tag plus-houkatsu`}>NLP</span>
                )}
                <span className={`tag apple`}>{appleStatus}</span>
              </span>
            </div>
          </div>
        </section>
        {data.runningBalance && (
          <section className={styles['info-section']}>
            <div>
              <div className={styles.label}>
                今月度利用残高合計 (Paidy Plus/Classic Outstanding)
              </div>
              <div className={styles.value}>
                {currencyFormatter.format(
                  data.runningBalance.plusOutstandingAmount || 0
                )}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                Apple今月度利用残高合計(Apple Outstanding)
              </div>
              <div className={styles.value}>
                {currencyFormatter.format(
                  data.runningBalance.appleOutstandingAmount || 0
                )}
              </div>
            </div>
            <div>
              <div className={styles.label}>請求金額 (Overdue)</div>
              <div className={styles.value}>
                {currencyFormatter.format(
                  data.runningBalance.overdueAmount || 0
                )}
              </div>
            </div>
            <div>
              <div className={styles.label}>未確定金額合計 (Uncaptured)</div>
              <div className={cx(styles.value)}>
                {currencyFormatter.format(
                  data.runningBalance.uncapturedAmount || 0
                )}
              </div>
            </div>
            <div>
              <div className={styles.label}>
                超あとはらい (Future Chouatobarai Amount)
              </div>
              <div className={cx(styles.value)}>
                {currencyFormatter.format(
                  data.runningBalance.futurePlusAmount || 0
                )}
              </div>
            </div>
          </section>
        )}
      </Card>
      <BillStatements
        billStatements={billstatementsWithSettlements}
        consumerId={consumerId}
      />
      <Suspense fallback={<LoadingPartial />}>
        <Charges consumerId={consumerId} />
      </Suspense>
      <Suspense fallback={<LoadingPartial />}>
        <MultiPayContracts consumerId={consumerId} />
      </Suspense>
    </>
  );
};

export default AccountPage;
