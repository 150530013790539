// @flow
import React, { Component, Suspense, useEffect, lazy } from 'react';
import { Router, Redirect, navigate } from '@reach/router';
import { ApolloProvider } from '@apollo/react-hooks';
import idx from 'idx';

import LoginPage from './components/LoginPage/LoginPageContainer';
import Header from './components/Header/Header';
import Loading from './components/Loading/Loading';
import SearchPage from './components/SearchPage/SearchPageContainer';
import PaymentPage from './components/PaymentPage/PaymentPage';
import ConsumerPage from './components/ConsumerPage/ConsumerPage';
import AccountPage from './components/AccountPage/AccountPage';
import MerchantPage from './components/MerchantPage/MerchantPage';
import BillStatementPage from './components/BillStatementPage/BillStatementPage';
import CombiniPaymentPage from './components/CombiniPaymentPage/CombiniPaymentPage';
import InstallmentPlanCancelPage from './components/InstallmentPlanCancelPage/InstallmentPlanCancelPage';

import axios from './axios';
import { clientCreator } from './graphql';
import type { MessagesType } from './types/message';

import styles from './App.module.scss';

const Messages = lazy(() => import('./components/Messages/Messages'));

const App = ({
  userId,
  messages,
  searchType,
  searchKeyword,
  updateUIPairs,
  updateUIToBeCachedPairs,
}: {
  userId: ?string,
  messages: MessagesType,
  searchType: string,
  searchKeyword: string,
  updateUIPairs: Function,
  updateUIToBeCachedPairs: Function,
}) => {
  const client = clientCreator();

  useEffect(() => {
    axios
      .get(`/profile/me2`)
      .then(resp => {
        const email = idx(resp, _ => _.data.email);

        if (email) {
          updateUIToBeCachedPairs({
            userId: email,
          });
        }
      })
      .catch(err => {
        console.log({ err });

        updateUIToBeCachedPairs({
          userId: '',
        });
      });
  }, []);

  useEffect(() => {
    if (searchKeyword) {
      navigate(`/search/${searchType}`);
    }
  }, [searchKeyword, searchType]);

  if (!userId) {
    return <LoginPage />;
  }

  return (
    <ApolloProvider client={client}>
      <div className={styles.root}>
        <Header
          userId={userId}
          updateUIToBeCachedPairs={updateUIToBeCachedPairs}
          updateUIPairs={updateUIPairs}
        />
        <main className={styles.main}>
          <Router primary={false}>
            {/** $FlowFixMe */}
            <Redirect from="/" exact to="search/payments" />
            <SearchPage path="search/*" />
            {/** $FlowFixMe */}
            <PaymentPage path="payments/:paymentId/*" />
            {/** $FlowFixMe */}
            <ConsumerPage path="consumers/:consumerId/*" />
            {/** $FlowFixMe */}
            <AccountPage path="accounts/:consumerId/*" />
            {/** $FlowFixMe */}
            <MerchantPage path="merchant/:tabName" />
            {/** $FlowFixMe */}
            <BillStatementPage path="consumers/:consumerId/billstatements/:billStatementId" />
            {/** $FlowFixMe */}
            <CombiniPaymentPage path="combini-payments/:combiniPaymentId" />
            <InstallmentPlanCancelPage path="installmentPlanCancel" />
            <Redirect from="*" to="/" />
          </Router>
          <Suspense fallback={null}>
            <Messages messages={messages} />
          </Suspense>
        </main>
      </div>
    </ApolloProvider>
  );
};

class ErrorBoundary extends Component<any> {
  constructor(props) {
    super(props);

    // $FlowFixMe
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    // $FlowFixMe
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    // $FlowFixMe
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {/* $FlowFixMe */}
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default (props: object) => (
  <Suspense fallback={<Loading />}>
    <ErrorBoundary>
      <App {...props} />
    </ErrorBoundary>
  </Suspense>
);
