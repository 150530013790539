import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link, Router, RouteComponentProps } from '@reach/router';

import LoadingPartial from '../Loading/LoadingPartial';
import NavLink from '../NavLink/NavLink';
import Card from '../Card/Card';
import Auth from './Auth/Auth';
import Captures from './Captures/Captures';
import Contract from './Contract/Contract';
import Assessments from './Assessments/Assessments';
import Refunds from './Refunds/Refunds';
import Messages from './Messages/Messages';

import gqlQuery, { getPaymentActiveFraudFlags } from './gql-query';
import { getTimeString, currencyFormatter, onError } from '../../utils';
import {
  FRAUD_PERMISSION,
  useIsShowAssessmentReport,
} from '../../utils/hooks/use-fraud-permission';

import styles from './PaymentPage.module.scss';
import FraudFlagLogs from './FraudFlagLogs/FraudFlagLogs';
import {
  withPermissionChecker,
  PermissionChecker,
} from '../PermissionChecker/PermissionChecker';
import SCSFlag from '../SCSFlag/SCSFlag';

type PaymentPageProps = {
  paymentId: string;
};

const useActivePaymentFraudFlags = (paymentId: string) => {
  const { data } = useQuery(getPaymentActiveFraudFlags, {
    variables: { paymentId },
    fetchPolicy: 'cache-and-network',
  });

  const activeFraudFlags =
    data?.activePaymentFraudFlags?.fraudStatus?.activeFraudFlags || [];

  return { activeFraudFlags };
};

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;

const PaymentPage = ({ paymentId }: PaymentPageProps) => {
  const isShowAssessmentReport = useIsShowAssessmentReport();
  const { activeFraudFlags } = useActivePaymentFraudFlags(paymentId);

  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { paymentId },
  });

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.payment) {
    return (
      <Card>
        <h3>ペイメント情報</h3>
        <div className="na-box payment" />
      </Card>
    );
  }

  const showFraudulentFlag = activeFraudFlags.length > 0;
  const FraudFlagLogsWithPermission = withPermissionChecker(FraudFlagLogs);

  return (
    <>
      <Card>
        <h3>ペイメント情報</h3>
        <h4 className={styles.id}>
          ペイメントID<span>{paymentId}</span>
          {data.payment.tags &&
            !!data.payment.tags.length &&
            data.payment.tags.indexOf('tatekae') > -1 && (
              <span className="tag big">big</span>
            )}
          {showFraudulentFlag && <span className="tag red">FRAUDULENT</span>}
        </h4>
        <section className={styles['info-section']}>
          <div className={styles.status}>
            <h5>ステータス</h5>
            <div className={styles.value}>
              <span
                className={`tag ${
                  data.payment.status ? data.payment.status.toLowerCase() : ''
                }`}
              >
                {data.payment.status}
              </span>
            </div>
          </div>
          <div className={styles.merchant}>
            <div className={styles.flex}>
              <h5>加盟店</h5>
              <SCSFlag
                show={data.payment.isSpecifiedContinuousServiceMerchant}
              />
            </div>
            <div className={styles.value}>{data.payment.merchantName}</div>
          </div>
          <div className={styles.consumer}>
            <h5>顧客情報</h5>
            <div className={styles.value}>
              {data.payment.consumerId ? (
                <>
                  {/* $FlowFixMe */}
                  <Link to={`/consumers/${data.payment.consumerId}/details`}>
                    詳細を見る
                  </Link>
                </>
              ) : (
                'N/A'
              )}
            </div>
          </div>
          <div className={styles['created-at']}>
            <h5>作成日</h5>
            <div className={styles.value}>
              {getTimeString(new Date(data.payment.createdAt))}
            </div>
          </div>
          <div className={styles.amount}>
            <h5>金額</h5>
            <div className={styles.value}>
              {currencyFormatter.format(data.payment.amount)}
            </div>
          </div>
          <div className={styles.capture}>
            <h5>キャプチャー</h5>
            <div className={styles.value}>
              {!data.payment.captures || !data.payment.captures.length ? (
                <span className="tag uncaptured">uncaptured</span>
              ) : (
                <>{currencyFormatter.format(data.payment.capturedAmount)}</>
              )}
            </div>
          </div>
          <div className={styles.refund}>
            <h5>リファンド金額</h5>
            <div className={styles.value}>
              {currencyFormatter.format(data.payment.refundedAmount)}
            </div>
          </div>
          <div className={styles.unconfirmed}>
            <h5>未確定金額</h5>
            <div className={styles.value}>
              {currencyFormatter.format(
                data.payment.amount - data.payment.capturedAmount
              )}
            </div>
          </div>
        </section>
      </Card>
      <Card>
        <nav className="tabs">
          <NavLink title="オーソリ" to={`/payments/${paymentId}/auth`}>
            オーソリ
          </NavLink>
          <NavLink title="キャプチャー" to={`/payments/${paymentId}/captures`}>
            キャプチャー
          </NavLink>
          <NavLink title="コントラクト" to={`/payments/${paymentId}/contract`}>
            コントラクト
          </NavLink>
          {isShowAssessmentReport && (
            <NavLink
              title="Assessments"
              to={`/payments/${paymentId}/assessments`}
            >
              Assessments
            </NavLink>
          )}
          <NavLink title="リファンド" to={`/payments/${paymentId}/refunds`}>
            リファンド
          </NavLink>
          <NavLink
            title="メッセージログ"
            to={`/payments/${paymentId}/messages`}
          >
            メッセージログ
          </NavLink>
          <PermissionChecker
            permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
          >
            <NavLink title="Fraud" to={`/payments/${paymentId}/fraud`}>
              Fraud
            </NavLink>
          </PermissionChecker>
        </nav>
        <Router primary={false}>
          <RouterPage
            path="auth"
            pageComponent={<Auth payment={data.payment} />}
          />
          <RouterPage
            path="captures"
            pageComponent={<Captures captures={data.payment.captures} />}
          />
          <RouterPage
            path="assessments"
            pageComponent={
              <Assessments
                reports={data.paymentAssessmentReportsByPaymentId.reports}
              />
            }
          />
          <RouterPage
            path="contract"
            pageComponent={<Contract contract={data.payment.contractData} />}
          />
          <RouterPage
            path="refunds"
            pageComponent={<Refunds refunds={data.payment.refunds} />}
          />
          <RouterPage
            path="messages"
            pageComponent={<Messages messages={data.paymentMessages} />}
          />
          <RouterPage
            path="fraud"
            pageComponent={
              <FraudFlagLogsWithPermission
                permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_READ]}
                activeFraudFlags={activeFraudFlags}
              />
            }
          />
        </Router>
      </Card>
    </>
  );
};

export default PaymentPage;
