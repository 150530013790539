import React, { useState } from 'react';
import { Link, Location } from '@reach/router';
import NavLink from '../NavLink/NavLink';

import axios from '../../axios';

import styles from './Header.module.scss';

type HeaderProps = {
  userId?: string;
  updateUIPairs: Function;
  updateUIToBeCachedPairs: Function;
};

const normalizeSearchKeyword = (input: string): string => {
  const stripped = input.trim();
  const regex = /^0([7-9]{1})0\d{8}$/;
  const matches = regex.exec(stripped);

  if (matches && matches.length >= 2) {
    return `81${matches[1]}0${stripped.substr(3)}`;
  }

  return stripped;
};

const Header = ({
  userId,
  updateUIPairs,
  updateUIToBeCachedPairs,
}: HeaderProps) => {
  const [inputKeyword, setInputKeyword] = useState('');

  const onLogout = () => {
    axios
      .delete(`/auth/session2`)
      // .catch().then() is alternative way of .finally since finally is not well supported now
      .catch((err: any) => {
        if (
          err.response.status === 401 &&
          err.response.data === 'Session not found'
        ) {
          return '';
        }

        throw err;
      })
      .then(() => {
        updateUIToBeCachedPairs({
          userId: '',
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onSearch = (event: any) => {
    const code = event.keyCode ? event.keyCode : event.which;

    if (code === 13) {
      const searchKeyword = normalizeSearchKeyword(inputKeyword);

      if (searchKeyword !== inputKeyword) {
        setInputKeyword(searchKeyword);
      }

      updateUIPairs({ searchKeyword });
    }
  };

  return (
    <div className={styles['header-wrapper']}>
      <header>
        <Link to="search/payments" className={styles.logo}>
          &nbsp;
        </Link>
        <Location>
          {({ location }) => {
            if (location.pathname.startsWith('/login')) {
              return null;
            }

            return (
              <>
                <input
                  className={styles['input-search']}
                  type="search"
                  id="search_submit"
                  data-testid="search_submit"
                  value={inputKeyword}
                  placeholder="ID、IdemiaID、VAN、名前、電話番号またはメールアドレスで検索（最低2字）"
                  onChange={event => {
                    setInputKeyword(event.currentTarget.value);
                  }}
                  onKeyPress={onSearch}
                />
                <div>
                  {!!userId && <span className={styles.account}>{userId}</span>}
                  <NavLink
                    title={'Installment Plans Cancel'}
                    to="installmentPlanCancel"
                    className={styles['btn-auth']}
                  >
                    Installment Plans Cancel
                  </NavLink>
                  <button
                    className={styles['btn-auth']}
                    onClick={onLogout}
                    data-testid="signout_btn"
                  >
                    ログアウト
                  </button>
                </div>
              </>
            );
          }}
        </Location>
      </header>
    </div>
  );
};

export default Header;
